const getDefaultState = () => {
  return {
    filteredList: [],
    status: 'empty'
  }
}

const state = getDefaultState()

const DivisionModule = {
  firestorePath: 'competitionTypes',
  firestoreRefType: 'collection',
  moduleName: 'CompetitionTypesModule',
  statePropName: 'data',
  namespaced: true,

  // this object is your store module (will be added as '/CompetitionTypesModule')
  // you can also add state/getters/mutations/actions
  state: state,
  getters: {
    filterBySeason: (state: { data: { [s: string]: unknown } | ArrayLike<unknown> }) => (season: any) => {
      if (!season) return state
      return Object.values(state.data).filter(e => (e as any).seasonId === season).sort((a, b) => {
        return (a as any).order - (b as any).order
      })
    }
  },
  mutations: {
    resetSeasonState (state: any) {
      Object.assign(state, getDefaultState())
    },
    FILTER_LIST: (state: { filteredList: any }, payload: any) => {
      state.filteredList = payload
    }
  },
  actions: {
    resetSeasonState ({ commit }) {
      commit('resetState')
    },
    filterBySeason: ({ commit, state }: any, season: { season: string }) => {
      const keys = Object.keys(state.data)
      const rs = keys.reduce(function (a: any, k) {
        if (state.data[k].seasonId === season.season) {
          a.push(state.data[k])
        }
        return a
      }, [])
      rs.sort((a: { order: number }, b: { order: number }) => {
        return a.order - b.order
      })
      commit('FILTER_LIST', rs)
    }
  }
}

export default DivisionModule
