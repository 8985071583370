
import Vue from 'vue'
import EventBus, { ACTIONS } from './eventBus/index'

export default Vue.extend({
  name: 'App',
  data: () => ({
    snackbar: {
      visible: false,
      duration: 2500,
      message: '',
      color: ''
    }
  }),
  computed: {
    layout () {
      if (this.$route.meta !== undefined && 'layout' in this.$route.meta) {
        return this.$route.meta.layout + '-layout'
      }
      return 'auth-layout'
    }
  },
  mounted () {
    EventBus.$on(ACTIONS.SNACKBAR, (data: any) => {
      this.snackbar.message = data.message
      this.snackbar.duration = ('duration' in data.options) ? data.options.duration : 2500
      this.snackbar.color = ('color' in data.options) ? data.options.color : 'secondary'
      this.snackbar.visible = true
    })
  }
})
