const GroupsModule = {
  firestorePath: 'groups',
  firestoreRefType: 'collection',
  moduleName: 'GroupModule',
  statePropName: 'data',
  namespaced: true,

  state: {},
  getters: {
    filterByCompetition: (state: { data: { [s: string]: unknown } | ArrayLike<unknown> }) => (competitionId: any) => {
      if (!competitionId) return state
      return Object.values(state.data).filter(e => (e as any).competitionId === competitionId).sort((a: any, b: any) => a.name.localeCompare(b.name)).sort((a: any, b: any) => a.order - b.order)
    },
    filterByGroup: (state: { data: ArrayLike<unknown> | { [s: string]: unknown } }) => (groupId: any) => {
      if (!groupId) return state
      const list = Object.values(state.data).filter(e => (e as any).id === groupId).sort((a: any, b: any) => a.name.localeCompare(b.name))
    },
    filterTeamsByGroup: (state: { data: ArrayLike<unknown> | { [s: string]: unknown } }) => (groupId) => {
      if (!groupId) return state
      return Object.values(state.data).filter(e => (e as any).id === groupId)
    },
    getIds: (state: { data: ArrayLike<unknown> | { [s: string]: unknown } }) => (competitionId: any) => {
      if (!competitionId) return state
      const list = Object.values(state.data).filter(e => (e as any).competitionId === competitionId).map(e => (e as any).id)
      return list
    }
  },
  mutations: {},
  actions: {}
}

export default GroupsModule
