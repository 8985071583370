import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c(VNavigationDrawer,{attrs:{"app":"","color":"primary","expand-on-hover":_vm.hover,"mini-variant":_vm.miniVariant,"right":false,"permanent":_vm.permanent,"fixed":true,"clipped":"","dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"shaped":""}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.itemClicked(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(item.title)))])],1)],1)}),1)],1),_c(VAppBar,{attrs:{"app":"","color":"dark","flat":true,"clipped-left":_vm.$vuetify.breakpoint.mdAndUp,"dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VSpacer),_vm._v(" "+_vm._s(_vm.user.displayName)+" "),_c(VBtn,{staticClass:"mx-2",attrs:{"icon":""}},[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"light","size":"10","x-large":"","dark":""}},[_vm._v(" mdi-account ")])],1)]}}])},[_c(VList,_vm._l((_vm.profileMenu),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.itemClicked(item)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1)],1)],1),_c(VMain,[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }