
import Vue from 'vue'
import router from '../router'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

interface Menu {
  title: string;
  route?: string;
  action?: string;
}

export default Vue.extend({
  name: 'Default',
  data: () => ({
    drawer: null,
    miniVariant: true,
    permanent: false,
    hover: true,
    title: '' as string,
    user: {
      displayName: ''
    },
    profileMenu: [
      { title: 'profile', route: '/profile' },
      { title: 'logout', action: 'signout', route: null }
    ],
    items: [
      { title: 'competition', icon: 'mdi-tournament', route: (localStorage.getItem('active-competition-path')) ? '/' + localStorage.getItem('active-competition-path') : '/competitions' },
      { title: 'clubs', icon: 'mdi-home', route: '/clubs' },
      { title: 'players', icon: 'mdi-account-multiple', route: '/players' },
      { title: 'referees', icon: 'mdi-police-badge', route: '/referees' },
      { title: 'venues', icon: 'mdi-map-marker', route: '/venues' }
    ],
    mini: false
  }),
  created () {
    this.$store.dispatch('SettingsModule/fetchAndAdd')
  },
  mounted () {
    this.user.displayName = firebase.auth().currentUser.displayName
    this.title = this.$t('app-title') as string
    document.title = this.$t('app-title') as string
  },
  methods: {
    getTranslated (string: string) {
      return this.$t(string)
    },
    itemClicked: function (item: Menu) {
      const name = 'signout'
      if ('route' in item && item.route !== null) {
        router.push({ path: item.route }).catch(() => ({}))
      } else if ('action' in item) {
        if (item.action !== null) {
          // eslint-disable-next-line
          const name = item.action
        }
        const fn = this[name]
        if (typeof fn === 'function') fn()
      }
    },
    getActiveRoute (): string {
      if (localStorage.getItem('current-competition-path') !== null) {
        return '/' + localStorage.getItem('current-competition-path')
      }
      return '/competitions'
    },
    signout: function () {
      this.$store.dispatch('SettingsModule/closeDBChannel', { clearModule: true })
      this.$store.dispatch('MatchModule/closeDBChannel', { clearModule: true })
      firebase.auth().signOut().then(() => {
        this.$router.replace('/login')
      })
    }
  }
})
