import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/remote-config'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import Default from './layouts/Default.vue'
import AuthLayout from './layouts/Auth.vue'

const remoteConfig = firebase.remoteConfig()
remoteConfig.defaultConfig = {
  adminIds: '{"list":[]}',
  languages: '[{ "name": "English", "value": "en" }]',
  modules: '[{"import_matches":true,"import_players":false,"scoretable":false}]'
}
if (process.env.VUE_APP_DEVELOPMENT === '1') {
  firebase.firestore().useEmulator('localhost', 5014)
  firebase.functions().useEmulator('localhost', 5005)
  remoteConfig.settings.minimumFetchIntervalMillis = 10
} else {
  remoteConfig.settings.minimumFetchIntervalMillis = 3600
}

remoteConfig.fetchAndActivate()

Vue.component('DefaultLayout', Default)
Vue.component('AuthLayout', AuthLayout)

Vue.config.productionTip = false
// eslint-disable-next-line
let app: any
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
      i18n
    }).$mount('#app')
  }
})
