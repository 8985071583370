const VenuesModule = {
  firestorePath: 'venues',
  firestoreRefType: 'collection',
  moduleName: 'venues',
  statePropName: 'data',
  namespaced: true,

  state: {},
  getters: {
    list: (state: any) => {
      return Object.values(state.data)
    }
  },
  mutations: {},
  actions: {}
}

export default VenuesModule
