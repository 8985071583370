import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"align":"center"}},[_c(VImg,{attrs:{"max-height":"250","max-width":"250","src":require("../assets/404-not-found.png")}}),_c('p',{staticClass:"title mt-5"},[_vm._v(" "+_vm._s(_vm.$t('404-sorry-not-found'))+" ")]),_c(VBtn,{attrs:{"color":"primary","elevation":"0","onclick":"location.href='/'"}},[_vm._v(" "+_vm._s(_vm.$t('back-to-app'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }