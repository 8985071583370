import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'

import VenuesModule from './modules/venues'
import ClubsModule from './modules/clubs'
import ClubTeamsModule from './modules/teams'
import PlayersModule from './modules/players'
import SeasonModule from './modules/seasons'
import DivisionsModule from './modules/divisions'
import CompetitonsModule from './modules/competitions'
import RefereesModule from './modules/referees'
import MatchModule from './modules/matches'
import GroupsModule from './modules/groups'
import StaffModule from './modules/staff'
import SettingsModule from './modules/settings'
import User from './modules/user'

import firebase from 'firebase/compat/app'

Vue.use(Vuex)

const easyFirestore = VuexEasyFirestore(
  [SeasonModule, DivisionsModule, CompetitonsModule, MatchModule, GroupsModule, ClubsModule, ClubTeamsModule, PlayersModule, RefereesModule, VenuesModule, StaffModule, SettingsModule, User],
  { logging: !!+process.env.VUE_APP_FIREBASE_LOGGING, FirebaseDependency: firebase }
)
const storeData = {
  plugins: [easyFirestore]
}

const store = new Vuex.Store(storeData)

export default store
