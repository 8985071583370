/* eslint-disable */
const CompetitonsModule = {
  firestorePath: 'competitions',
  firestoreRefType: 'collection',
  moduleName: 'CompetitonsModule',
  statePropName: 'data',
  namespaced: true,

  state: {
    filteredList: []
  },
  getters: {
    filterByType: (state) => (typeId: string) => {
      if (!typeId) return state
      return Object.values(state.data).filter(e => (e as any).typeId === typeId).sort((a, b) => {
        return (a as any).order - (b as any).order
      })
    },
    getIds: (state) => (typeId: string) => {
      if (!typeId) return state
      const list = Object.values(state.data).filter(e => (e as any).typeId === typeId).map(e => (e as any).id)
      return list
    }
  },
  mutations: {
    // FILTER_LIST: (state, payload) => {
    //   state.filteredList = payload
    // }
  },
  actions: {
    // filterByType: ({ commit, state }, data) => {
    //   const keys = Object.keys(state.data)
    //   const rs = keys.reduce(function (a, k) {
    //     if (state.data[k].typeId === data.typeId) {
    //       a.push(state.data[k])
    //     }
    //     return a
    //   }, [])
    //   commit('FILTER_LIST', rs)
    // }
  }
}

export default CompetitonsModule
