export default {
  config: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
  }
}

// config: {
//   type: 'service_account',
//   projectId: 'hsf-develop',
//   apiKey: '7af3d8e1e97b4e8c6bcfdfa6c1020fbf9dc8fb89',
//   private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQChgL3xYIPjLWYN\nn1uns7dFjs9A+yBIFhMMC8l17H3IY2lr4p4lRQR5mO8ln6bkIF0juFzszPL5Icog\ni7Rl1UUao4krH2vFqzJHtYjPpIvpJ6ytX4nthYNY5WbsAQ+6MEdvyQhR62n0y1d8\nkHT5lPS8epG1A20ls/T5XvfhctMv/ZjSWg+2U7nivwCTgmwYsCFUyvX61EgQv7tg\nEcWEVf8iUV++1sdaM2Bp1s6F2T0zhPPoo6EjgBbtY+2Vj2ry2XRULRX8WWmeYugR\numvOQWpwJvs0c1XbQHXf55jsYou9ctgjSpY0WdNpD1zBGXNPp8pwhXW5ra1wquoo\nfV6LvXapAgMBAAECggEACOlZ8cb92AwCANftfoo1325zJLaGsDuZlvVSi0F+qk9D\n+qpBSDSv+8ay0XxnANkBUq6CYCWp9ZHF/bg/vsu/Gapy7/B2qaxZ5KeRckW126pv\nBo0KRxMyLXZREM45MDCoTHSHE0Aec13CCNNOxRPRUlIgFqEnBWjR6G2zV1sW6KOq\n+MItsTHO4UqBEq99eL8T/S8Xv0SMjcsZ/RZceV7YY8M+HZUKe41/B94/SlazkWZW\nNlL/If/dl60sEmlVyVAVmbGO0Y8qLSuG5XWLREZqURcAD6F0fZB8gFWv82NAXg2t\nP4FTLW5h5Rq6xQ1yzS6rYU8yc7cTUduQWps8YSRWYQKBgQDPJ0WfdemBHKF3/yhB\nqSrGqE5/en30Dem5J1ZWE7Hsd8TSaOipNIj9d8ZM+Ql1LzqlU2rdNkCdeOWYrZfa\nmvdgqKBFwrgqDjqRRO+qG0kE+Se+5NhPeKDRHRFc+pClr9txvF8DzKLY95gsuO5w\niZSow5+03/0K5Nrz7uTKQXhZEQKBgQDHlcvc6HBN+Ayfu4xvMWFN+8jenDVH+E/6\n870cvTJzpJxh8+0EEk46SRC5k1M2ZC8mhWuh0ryTBokTC91tgNzlwoK+DwzIHTID\n3l7IlCU39pONChrUng4FTEz8CD8cqjbxXuXcOPzzS1Fbwtj9zx7TT4SXUdJCKwxr\np/iuCBCEGQKBgCSEdsiKf7RL+MbviwgwKyNEDjp4vHVmcno6TgT0idv2rRKufb0+\nTpvoHmP5TsqCneMBFoiShMd9xXOpvmhCAzPd5gsGLXL0eSycq2cgUjn3NiN3sg4o\nZKkYZ7QtU4xxuMyO+WzxCRAYbZIMs7PQ/QzXF3NKPRBLkbGIvnpNGksRAoGBAJyF\nOW9xSkGbOis6phO9ohXKU+lgj9h1QCQKalDTf3F5srqO1V3QDVFgY6FljGingOKw\n14v8/K5A/dbWAz0RRitZs7bhjqCRZNvr1GFw7Q9KbMS7G/eKTGyTPfyZawVL5/K6\n/YELoDQc6R0RXaMOf5owTkdtf3ssz3zOKhQxduJhAoGAEIZ6+msaiZqRyY9BDiET\nXKZ3HzSVMWkw/GdLC3tEG6YmTbJpvJz3eai5A080aVPhcZOd8mg4c2uFCKPJ+RIm\nEpzB9adjWn5uyGIshJ5DJun7sr2RYEuz9egisWO9V6Lj9BgUk5bfzoyJooJBRLNl\ndADxl6DZMVfc1P4ylTRTiTw=\n-----END PRIVATE KEY-----\n',
//   client_email: 'firebase-adminsdk-ab5tj@hsf-develop.iam.gserviceaccount.com',
//   client_id: '116780993484989790754',
//   auth_uri: 'https://accounts.google.com/o/oauth2/auth',
//   token_uri: 'https://oauth2.googleapis.com/token',
//   auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
//   client_x509_cert_url: 'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-ab5tj%40hsf-develop.iam.gserviceaccount.com',
//   universe_domain: 'googleapis.com'
// }
