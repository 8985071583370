const ClubsModule = {
  firestorePath: 'referees',
  firestoreRefType: 'collection',
  moduleName: 'RefereesModule',
  statePropName: 'data',
  namespaced: true,

  // this object is your store module (will be added as '/RefereesModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getList: (state: any) => {
      return Object.values(state.data)
    }
  },
  mutations: {},
  actions: {}
}

export default ClubsModule
