import { AnyObject } from 'vuex-easy-firestore/types/declarations'

const UserModule = {
  firestorePath: 'users/{userId}',
  firestoreRefType: 'doc',
  moduleName: 'user',
  statePropName: 'data',
  namespaced: true,
  sync: {
    preventInitialDocInsertion: true
  },
  state: {
    clubId: null,
    userData: {},
    authData: {}
  },
  getters: {
    canManage: (state: AnyObject) => {
      const obj = state.data
      if ('role' in obj) {
        return obj.role.includes('admin')
      }
      return false
    }
  },
  mutations: {},
  actions: {}
}

export default UserModule
