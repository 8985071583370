const StaffModule = {
  firestorePath: 'users',
  firestoreRefType: 'collection',
  moduleName: 'StaffModule',
  statePropName: 'data',
  namespaced: true,

  state: {},
  getters: {
    filterByClub: (state) => (clubId) => {
      if (!clubId) return state
      return Object.values(state.data).filter(e => (e as any).club === clubId)
    }
  },
  mutations: {},
  actions: {}
}

export default StaffModule
