const ClubsModule = {
  firestorePath: 'clubs',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'ClubsModule',
  statePropName: 'data',
  namespaced: true,

  // this object is your store module (will be added as '/DivisionsModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    all: (state: { data: { [s: string]: unknown } | ArrayLike<unknown> }) => {
      return Object.values(state.data)
    }
  },
  mutations: {},
  actions: {}
}

export default ClubsModule
