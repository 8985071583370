const ClubTeamsModule = {
  firestorePath: 'teams',
  firestoreRefType: 'collection',
  moduleName: 'ClubTeamsModule',
  statePropName: 'data',
  namespaced: true,
  state: {},
  getters: {
    sortedTeams (state) {
      return Object.values(state.data)
    },
    filterByClub: (state) => (clubId) => {
      if (!clubId) return state
      const list = Object.values(state.data).filter(e => (e as any).clubId === clubId)
      list.sort((a, b) => {
        const x = (a as any).name.toLowerCase()
        const y = (b as any).name.toLowerCase()
        return x < y ? -1 : x > y ? 1 : 0
      })
      return list
    },
    filterForGroup: (state: any) => (gender: string, age: any) => {
      if (!gender) return state
      const ageCheck = (typeof age === 'object') ? age.name : age
      const list = Object.values(state.data).filter((e: { gender: string, ageGroup: any}) => {
        return e.gender === gender && e.ageGroup === ageCheck
      })
      list.sort((a, b) => {
        const x = (a as any).clubId.toLowerCase()
        const y = (b as any).clubId.toLowerCase()
        return x < y ? -1 : x > y ? 1 : 0
      })
      return list
    }
  },
  mutations: {},
  actions: {}
}

export default ClubTeamsModule
