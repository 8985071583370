const MatchModule = {
  firestorePath: 'matches',
  firestoreRefType: 'collection',
  moduleName: 'MatchModule',
  statePropName: 'data',
  namespaced: true,
  sync: {
    preventInitialDocInsertion: true
  },
  // serverChange: {
  //   modifiedHook: function (updateStore: any, doc: any, id: string, store: any) { updateStore(doc) }
  // },
  state: {},
  getters: {
    filterByGroup: (state) => (groupId: string) => {
      return Object.values(state.data).filter(e => (e as any).groupId === groupId)
    },
    filterByGroupAndPlayed: (state) => (groupId: string) => {
      return Object.values(state.data).filter(e => (e as any).groupId === groupId && (e as any).state === 'APPROVED_BY_REFEREE')
    },
    getMatchIds: (state) => (groupId: string) => {
      if (!groupId) return []
      const list = Object.values(state.data).filter(e => (e as any).groupId === groupId).map(e => (e as any).matchId)
      return list
    },
    getEvents: (state: any) => (id: string) => {
      const document = Object.values(state.data).filter((e: any) => e.id === id).map(e => (e as any).eventLog)
      return document[0]
    }
  },
  mutations: {},
  actions: {}
}

export default MatchModule
