const SeasonModule = {
  firestorePath: 'seasons',
  firestoreRefType: 'collection',
  moduleName: 'SeasonModule',
  statePropName: 'data',
  namespaced: true,
  state: {},
  getters: {
    sortedDESC: (state) => {
      return Object.values(state.data).sort(function (a, b) {
        return (b as any).start - (a as any).start
      })
    },
    sortedASC: (state) => {
      return Object.values(state.data).sort(function (a, b) {
        return (a as any).start - (b as any).start
      })
    }
  },
  mutations: {},
  actions: {}
}

export default SeasonModule
