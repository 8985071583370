import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUK from './en/en-UK.json'
import foFO from './fo/fo-FO.json'

Vue.use(VueI18n)

const messages = {
  // eslint-disable-next-line
  'en': {
    ...enUK
  },
  // eslint-disable-next-line
  'fo': {
    ...foFO
  }
}

export default new VueI18n({
  fallbackLocale: 'en',
  locale: (localStorage.getItem('language')) ? localStorage.getItem('language') as string : 'en',
  messages
})
