import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { App } from '../firebase/app'
import 'firebase/compat/auth'
import NotFoundComponent from '../components/NotFound.component.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    component: require('@/views/Dashboard.vue').default
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    component: require('@/views/Dashboard.vue').default
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'auth'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/LoginPage.vue')
  },
  {
    path: '/register/byinvitation/:invitation',
    name: 'Register',
    meta: {
      layout: 'auth'
    },
    component: () => import(/* webpackChunkName: "RegisterWithEmail" */ '@/views/auth/RegisterPage.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/venues',
    name: 'Venues',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "locations" */ '@/views/venues/Venues.vue')
  },
  {
    path: '/clubs',
    name: 'Clubs',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "clubs" */ '../views/clubs/Clubs.page.vue')
  },
  {
    path: '/club/:id',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    props: true,
    component: () => import(/* webpackChunkName: "clubprofile" */ '@/views/clubs/Club.page.vue'),
    children: [
      {
        path: 'teams',
        name: 'TeamsPage',
        props: true,
        meta: {
          layout: 'default'
        },
        component: () => import(/* webpackChunkName: "teamscomponent" */ '@/components/Teams.component.vue')
      },
      {
        path: 'teams/:team',
        name: 'TeamPage',
        props: true,
        meta: {
          layout: 'default'
        },
        component: () => import(/* webpackChunkName: "teampage" */ '@/views/clubs/Team.page.vue')
      },
      {
        path: 'staff',
        meta: {
          layout: 'default'
        },
        component: () => import(/* webpackChunkName: "staffpage" */ '@/views/clubs/staff/Staff.page.vue'),
        children: [
          {
            path: '',
            name: 'StaffPage',
            meta: {
              layout: 'default'
            },
            component: () => import(/* webpackChunkName: "stafflist" */ '@/components/Staff.component.vue'),
            props: true
          }
        ]
      },
      {
        path: 'players',
        name: 'ClubPlayersPage',
        props: true,
        meta: {
          layout: 'default'
        },
        component: () => import(/* webpackChunkName: "teamscomponent" */ '@/views/clubs/players/Players.page.vue')
      }
    ]
  },
  {
    path: '/players',
    name: 'Players',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "players" */ '../views/players/Players.page.vue')
  },
  {
    path: '/referees',
    name: 'Referees',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "referees" */ '../views/referees/Referees.page.vue')
  },
  {
    path: '/competitions',
    meta: {
      layout: 'default',
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "CompetitionsComponent" */ '@/views/competitions/Competitions.page.vue'),
    children: [
      {
        path: '/',
        name: 'CompetitionsDashboard',
        meta: {
          layout: 'default'
        },
        component: () => import(/* webpackChunkName: "CompotitionDashboard" */ '@/views/competitions/Dashboard.page.vue')
      },
      {
        path: 'types',
        name: 'CompetitionTypes',
        meta: {
          layout: 'default'
        },
        component: () => import(/* webpackChunkName: "competitionTypesPage" */ '@/views/competitions/CompetitionTypes.page.vue')
      },
      {
        path: ':typeId',
        name: 'CompetitionType',
        meta: {
          layout: 'default'
        },
        component: () => import(/* webpackChunkName: "competitionTypePage" */ '@/views/competitions/CompetitionType.page.vue'),
        props: true
      },
      {
        path: ':competitionId',
        name: 'CompetitionPage',
        meta: {
          layout: 'default'
        },
        component: () => import(/* webpackChunkName: "competitionPage" */ '@/views/competitions/Competition.page.vue'),
        props: true
      },
      {
        path: ':competitionId/group/:groupId',
        name: 'GroupPage',
        meta: {
          layout: 'default'
        },
        beforeEnter (to, from, next) {
          next()
        },
        component: () => import(/* webpackChunkName: "groupPage" */ '@/views/competitions/Group.page.vue'),
        props: true
      },
      {
        path: ':matchId/report',
        name: 'MatchReport',
        meta: {
          layout: 'default'
        },
        beforeEnter (to, from, next) {
          next()
        },
        component: () => import(/* webpackChunkName: "matchReport" */ '@/components/MatchReport.component.vue'),
        props: true
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent,
    name: 'NotFound'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const current = App.auth().currentUser
  const requiresAuth = to.matched.some(x => x.meta.needsAuth)
  if (requiresAuth && !current) {
    next({
      path: '/login'
    })
  } else {
    next()
  }
})

export default router
