const SettingsModule = {
  firestorePath: 'settings',
  firestoreRefType: 'collection',
  moduleName: 'SettingsModule',
  statePropName: 'data',
  namespaced: true,
  sync: {
    preventInitialDocInsertion: true
  },
  state: {},
  getters: {
    getSetting: (state) => (setting: string) => {
      if (state.data[0] !== undefined && setting in state.data[0]) {
        return state.data['0'][setting]
      }
      return []
    }
  },
  mutations: {},
  actions: {}
}

export default SettingsModule
