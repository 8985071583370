const ClubsModule = {
  firestorePath: 'players',
  firestoreRefType: 'collection',
  moduleName: 'PlayersModule',
  statePropName: 'data',
  namespaced: true,

  // this object is your store module (will be added as '/DivisionsModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getList: (state: any) => {
      return Object.values(state.data)
    },
    filterByClub: (state) => (clubId) => {
      if (!clubId) return state
      const players = Object.values(state.data).filter(e => (e as any).current === clubId)
      return players
    },
    filterByTeam: (state) => (teamId) => {
      if (!teamId) return state
      const players = Object.values(state.data).filter(e => (e as any).teamId === teamId)
      return players
    }
  },
  mutations: {},
  actions: {}
}

export default ClubsModule
